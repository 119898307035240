#about {
  svg {
    display: block;
    margin: 0 auto;
  }
  #about-text {
    width: 85%;
    margin: 0 auto;
    p {
      text-align: left;
      margin: 20px auto;
      font-weight: 700;
      color: #999;
    }
    .mini-text {
      text-align: right;
      font-size: 0.75rem;
      font-style: italic;
    }
  }
}
@media screen and (min-width: 1024px) {
  #about {
    #about-text {
      width: 30%;
    }
  }
}
