:root {
  --mobile-width: 70vw;
}
.comic {
  margin: 10px 0;
  h2 {
    text-align: center;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > img {
      opacity: 0;
      width: var(--mobile-width);
      height: var(--mobile-width);
      margin: 0 0 10px 0;
      border-radius: 10px;
      border: 8px solid black;
      overflow: hidden;
      transition: opacity 0.5s;
      &.loaded {
        opacity: 1;
      }
      &:last-child {
        // margin-right: 0;
      }
    }
  }
  > div:nth-child(2) {
    flex-direction: column;
    width: var(--mobile-width);
    margin: 10px auto;
    #comic-date {
      width: 100%;
      text-align: right;
      font-weight: bold;
      font-size: 0.75rem;
    }
    #comments {
      width: 100%;
      textarea {
        width: 100%;
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .comic {
    width: 90vw;
    margin: 10px auto;
    > div:nth-child(1) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
	  margin: 0 auto;
	  width: 50%;
      > img {
        width: auto;
        height: auto;
        margin: 20px;
      }
    }
    > div:nth-child(2) {
      flex-direction: column;
      #comic-date {
        width: 100%;
        text-align: right;
      }
      #comments {
        width: 100%;
        textarea {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
