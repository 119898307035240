#pagination {
	text-align: center;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		button {
			display: inline;
			margin: 0 3px;
		}
	}
	a {
		font-weight: bold;
		color: #333;

		margin: 0 5px;
		&.active {
			font-weight: 900;
			font-size: 1.5rem;
		}
	}
	button {
		cursor: pointer;
		display: block;
		margin: 10px auto 0;
		border: none;
		padding: 5px 5px;
		border-radius: 5px;
		background-color: rgba(149, 149, 149, 0.25);
	}
}
