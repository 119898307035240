#contact {
	p {
		margin: 175px auto;
		transform: rotate(-10deg);
		width: 85%;
		font-weight: 900;
		&:nth-child(2) {
			transform: rotate(10deg);
		}
	}
}

@media screen and (min-width: 1024px) {
	#contact {
		p {
			margin: 100px auto;
			width: max-content;
		}
	}
}