#about-page-logo {
  display: block;
  margin: 0 auto;
  width: 85vw;
  .cls-1 {
	stroke-linecap: round;
	stroke-linejoin: round;
  }

  .cls-1, .cls-2 {
	fill: #231f20;
  }

  .cls-1, .cls-3 {
	stroke: #231f20;
  }

  .cls-4, .cls-5, .cls-6, .cls-7 {
	fill: #fff;
  }

  .cls-5, .cls-6, .cls-3, .cls-7 {
	stroke-miterlimit: 10;
  }

  .cls-5, .cls-6, .cls-7 {
	stroke-width: .5px;
  }

  .cls-5, .cls-7 {
	stroke: #ee2724;
  }

  .cls-6 {
	font-size: 17.18px;
	stroke: #42b649;
  }

  .cls-6, .cls-7 {
	font-family: AmericanTypewriter-Bold, 'American Typewriter';
	font-weight: 700; 
	animation-name: star;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 20s;
  }

  .cls-3 {
	fill: #f0f;
  }

  .cls-7 {
	font-size: 17.18px;
	animation-direction: reverse;
  }
  #words {
    transform-box: fill-box;
    transform-origin: center;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 20s;
  }
}

@media screen and (min-width: 1024px) {
	#about-page-logo {
		display: block;
		margin: 0 auto;
		width: 500px;
	}
  }


@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes star {
  0% {
    stroke: #d7df23;
  }
  10% {
    stroke: #00aeef;
  }
  20% {
    stroke: #ed2590;
  }
  30% {
    stroke: #fff200;
  }
  50% {
    stroke: #662d91;
  }
  60% {
    stroke: #2e3192;
  }
  70% {
    stroke: #39b54a;
  }
  80% {
    stroke: #ed1c24;
  }
  90% {
    stroke: #f15a29;
  }
  100% {
    stroke: #d7df23;
  }
}
