header {
	margin-bottom: 20px;
	nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px dotted black;
		height: 50px;
		padding: 0 10px;
		background-color: white;
		a {
			text-decoration: none;
			color: #333;
			img {
				position: relative;
				top:10px;
			}
		}
		ul {
			display: flex;
			list-style-type: none;
			li a {
				margin: 0 0 0 0.5rem;
				text-decoration: none;
				color: #333;
				font-weight: bold;
				&:hover {
					color: #999;
				}
			}
		}
	}
	h2 {
		text-align: center;
		font-size: 1rem;
	}
}
